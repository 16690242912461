import React, { CSSProperties, useEffect, useState } from "react";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import AceEditor from "react-ace";
import "./App.css";
import { stringify } from "javascript-stringify";
import { Button, Col, Row, Tooltip } from "antd";
import beautify from "js-beautify";
import CopyOutlined from "@ant-design/icons/CopyOutlined";

const FooterContainer: CSSProperties = {
  position: "fixed",
  marginTop: 30,
  bottom: 0,
  height: 60,
  width: "100%",
  backgroundColor: "#f2f2f2",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const footerItem: CSSProperties = {
  maxHeight: 50,
  display: "inline-block",
  color: "#616161",
  fontSize: 20,
  paddingRight: 24
};

const footerAttribute: CSSProperties = {
  position: "fixed",
  bottom: 5,
  right: 5,
  fontSize: 12,
  display: "inline-block",
  float: "right"
};

function App() {
  const [js, setJs] = useState<string>();
  const [json, setJson] = useState<string>(`{
    "name":"John",
    "age":30,
    "cars": {
      "car1":"Ford",
      "car2":"BMW",
      "car3":"Fiat"
    }
  }`);

  const [jsError, setJsError] = useState<string>();
  const [jsonMessage, setJsonMessage] = useState<string>();
  const [jsonError, setJsonError] = useState<string>();

  const onJsonChange = (value: string) => {
    setJsError(undefined);
    setJson(value);
    let converted: string = "";
    try {
      converted = stringify(JSON.parse(value));
      const finalJS = beautify.js_beautify(converted);
      setJs(finalJS);
    } catch (error) {
      setJsError("failed to parse json snippet");
    }
  };

  useEffect(() => {
    const appInsights = new ApplicationInsights({ config: {
      instrumentationKey: '13d05d11-14f6-47d1-8ae3-4498195aacfa'
      /* ...Other Configuration Options... */
    } });
    appInsights.loadAppInsights();
    appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
    onJsonChange(json);
    // eslint-disable-next-line
  }, []);

  const copy = (data: string) => {
    navigator.clipboard
      .writeText(data)
      .then((x) => setJsonMessage("copied result to your clipboard"))
      .catch((err) => {
        setJsonError("failed copied to your clipboard");
      });
  };

  const paste = () => {
    navigator.clipboard
      .readText()
      .then((x) => {
        setJson(x);
        onJsonChange(x);
      })
      .catch((err) => {
        setJsError("failed to read clipboard");
      });
  };

  return (
    <div>
      <header className="App-header">
        <h1 className="header-title">Convert JSON to JS</h1>
      </header>
      <div></div>
      <div>
        <Row gutter={[16, 1]}>
          <Col offset={1} span={11}>
            <div style={{ fontSize: "large", fontWeight: "bold" }}>Copy your JSON here</div>
            <AceEditor
              value={json}
              width={"90%"}
              mode="json"
              theme="github"
              onChange={onJsonChange}
              name="UNIQUE_ID_OF_DIV"
              editorProps={{ $blockScrolling: true }}
            />
          </Col>
          <Col offset={1} span={11}>
            <div style={{ fontSize: "large", fontWeight: "bold" }}>Get your javascript object string here</div>
            <AceEditor
              mode="javascript"
              width={"90%"}
              theme="github"
              name="UNIQUE_ID_OF_JSON_DIV"
              editorProps={{ $blockScrolling: true }}
              value={js}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{ color: "red", textAlign: "center" }}>{jsError}</div>
          </Col>
          <Col span={12}>
            <div style={{ color: "green", textAlign: "center" }}>{jsonMessage}</div>
          </Col>
          <Col span={12}>
            <div style={{ color: "red", textAlign: "center" }}>{jsonError}</div>
          </Col>
        </Row>
        <Row gutter={[0, 1]}>
          <Col offset={1} span={11}>
            <Button
              style={{ width: "100%", height: 100 }}
              type="primary"
              // shape="round"
              icon={<CopyOutlined />}
              size="large"
              onClick={paste}
            >
              <span style={{ fontSize: 20, fontWeight: "bold" }}>Paste from your clipboard</span>
            </Button>
          </Col>
          <Col offset={1} span={10}>
            <Button
              style={{ width: "100%", height: 100 }}
              type="primary"
              // shape="round"
              icon={<CopyOutlined />}
              size="large"
              onClick={() => copy(js)}
            >
              <span style={{ fontSize: 20, fontWeight: "bold" }}>Copy to your clipboard</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <div style={FooterContainer}>
            <div>
              <div style={footerItem}>
                <Tooltip placement="top" title="All You Data are not sent to any service, in other words, it's safe!">
                  Privacy
                </Tooltip>
              </div>
              <div style={footerItem}>
                <Tooltip placement="top" title="Free to use">
                  Term of usre
                </Tooltip>
              </div>
              <div style={footerItem}>
                <Tooltip placement="top" title="Just a tool">
                  About
                </Tooltip>
              </div>
              <div style={footerAttribute}>
                Icons made by{" "}
                <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
                  Freepik
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  {" "}
                  www.flaticon.com
                </a>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default App;
